import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}

import {
  SectionContainer,
  SectionInnerWrapper,
} from "../../../components/Layout/styled"

import Footer from "../../../components/Layout/Footer"
import SeoComp from "../../../components/SeoComp"

const NosRevendeurs = ({ intl, data }) => {
  const addScript = () => {
    const script = document.createElement("script")
    script.src =
      "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd54WcWBAICVaH1t1TB2N_2FuZzE7i_2B9E1C791o88eJDlto.js"
    // script.async = true
    document.body.appendChild(script)
    // let el = document.createElement("script");
    // el.src = "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd54WcWBAICVaH1t1TB2N_2FuZzE7i_2B9E1C791o88eJDlto.js";
    // document.body.appendChild(el);
  }

  useEffect(() => {
    addScript()
  })

  return (
    data &&
    data.allDirectusFooterTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          hreflangPathname={{
            fr: "nos-revendeurs",
            en: "our-resellers",
            de: "our-resellers",
            es: "our-resellers",
          }}
          noindex={true}
          nofollow={true}
        />
        <SectionContainer>
          <SectionInnerWrapper align="flex-start" justify="flex-start">
            <SmcxSdkWrapper>
              <SmcxSdkDiv id="smcx-sdk"></SmcxSdkDiv>
            </SmcxSdkWrapper>
          </SectionInnerWrapper>
        </SectionContainer>

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

const SmcxSdkWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;

  .smcx-embed {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  .smcx-embed > .smcx-iframe-container {
    width: 100%;
    max-width: 100%;
    height: 2250px;
  }
`

const SmcxSdkDiv = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "nos_revendeurs" } }
      }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(NosRevendeurs)
